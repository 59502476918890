<template>
   <div class="BannerMainDiv">
      <v-carousel hide-delimiters
            :continuous="true"
            :cycle="true"
            :show-arrows="false"
            height="auto"
            >
              <!-- <v-carousel-item>
                <img class="imgTag" src="@/assets/homeBanner4.jpg">
              </v-carousel-item> -->
              <v-carousel-item>
                <img class="imgTag" src="@/assets/homeBanner2.jpg" @click="redirecto_Book()">
              </v-carousel-item>
              <v-carousel-item>
                <img class="imgTag" src="@/assets/homeBanner3.png" @click="redirectto_Walkin()">
              </v-carousel-item>
              <!-- <v-carousel-item>
                <img class="imgTag" src="@/assets/homeBanner5.png">
              </v-carousel-item> -->
      </v-carousel>
  </div>
</template>

<script>
export default {
  name: 'banner',
  methods:{
    redirecto_Book(){
      this.$router.push({
        name:"BookAppointment",
      });
    },
    redirectto_Walkin(){
      this.$router.push({
        name:"ListOfWalkinDoctor",
      });
    },
  }
}
</script>
<style>
.imgTag{
  width: 95%;
  height: auto;
  border-radius: 8px;
}
.BannerMainDiv{
  height: fit-content;
}

/* @media (min-width: 800px){
  .imgTag{
   width: 40vw;
   height: fit-content;

  }
  .BannerMainDiv{
  height: 90%;
}} */

</style>

